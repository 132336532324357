import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, ConfigProvider, Dropdown, Menu } from 'antd';
import './header.scss';
import './dashboard.scss';
import { Query } from 'react-apollo';
import { GET_PROFILE } from './query';
import { pathOr } from 'ramda';
import { withTranslation } from 'react-i18next';
import i18n, { getSupportedLngs } from 'i18n';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      isTooltipOpened: false,
    };
  }

  renderLangSwitcher = () => {
    const supportedLangs = getSupportedLngs();
    const switchLng = (lng) => {
      localStorage.setItem('lng', lng);
      i18n.changeLanguage(lng);
    };
    if (supportedLangs.length > 1) {
      return supportedLangs.map((lang) => (
        <span
          className={`header__text-language ${i18n.language === lang ? 'header__text-language__active' : ''}`}
          onClick={() => switchLng(lang)}
        >
          {lang.toUpperCase()}
        </span>
      ));
    } else return null;
  };

  render() {
    const hexColor = window.parametrize('REACT_APP_PRIMARY_COLOR');

    const menu = (
      <Query query={GET_PROFILE}>
        {(result) => {
          let profileName = this.props.t('modules.header.profile_name.loading_user_data');
          if (result.error) profileName = this.props.t('modules.header.profile_name.error_retrieving_user_data');
          if (result.data) {
            let person = pathOr({}, ['users', 'getProfile', 'person'], result.data);
            let name = person?.name?.ru || person?.name?.en || '';
            let surname = person?.surname?.ru || person?.surname?.en || '';
            let patronymic = person?.patronymic?.ru || person?.patronymic?.en || '';
            profileName = `${surname} ${name} ${patronymic}`;
          }
          return (
            <Menu>
              <Menu.Item key="1">{profileName}</Menu.Item>
              <Menu.Item
                onClick={async (ev) => {
                  await this.props.authClient.logout();
                  window.location.reload();
                }}
                key="2"
              >
                {this.props.t('modules.header.menu_item')}
              </Menu.Item>
            </Menu>
          );
        }}
      </Query>
    );

    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#FFF',
            colorText: '#000000',
            controlItemBgHover: null,
          },
        }}
      >
        <header className="header">
          <div
            className={[
              'header__logo-container df jcsb aic ',
              this.props.collapsed ? 'header__logo-container--collapsed' : '',
            ].join('')}
            style={{ background: hexColor }}
          >
            <Link to="/admin" className="df aic jcsb">
              <img src={window.parametrize('REACT_APP_LOGO_SRC')} alt="logo"/>
              <span className="header__logo-title">{this.props.t('modules.header.span')}</span>
            </Link>
          </div>
          <div className="df jcsb aic header__info">
            <LegacyIcon
              onClick={() => {
                this.props.setCollapsed(!this.props.collapsed);
                document.querySelector('.aside__assembly').classList.toggle('aside__assembly_hidden');
              }}
              className="trigger"
              type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
            />
            <div className="df aic">
              {/* <Icon
              className="header__search"
              type="search"
              style={{ fontSize: 16 }}
              onClick={() => console.log('i')}
            />
            <Icon
              className="header__notifs"
              type="bell"
              style={{ fontSize: 16 }}
              onClick={() => console.log('i')}
            /> */}
              <div className="header__display-container_language-container">{this.renderLangSwitcher()}</div>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button id="HeaderAvatarButton" className="_reset">
                  <Avatar size={40} icon={<UserOutlined/>} style={{ background: hexColor }}/>
                </Button>
              </Dropdown>
            </div>
          </div>
        </header>
      </ConfigProvider>
    );
  }
}

export default withTranslation()(Header);
